import { Item } from "src/app/shared/fd-form-components/fd-select/fd-select.component";
import { TechnologyInformationDto } from "./technology-information-dto";

export class ParamRates {
  personType: string;
  cnae: string;
  annualBillingValue: number;
  idCampaign: number;
  campaignCode?: string;
  uf: string;
  receivingDeadline: number;
  chargeModelEnum: string;
  cpfCnpj: string;
  automaticPrepayment: boolean;
  offerMessage?: string;
  hasEconomicGroup: boolean;
  institutionId: string;
  serviceContract: number;
  campaignName?: string;
  techReport:  Array<TechReportDTO>;
}

export class TechReportDTO{
  name: string;
  value: string;
  totalValue:string;
  qtd: number;
  serviceId:string;
}

export class Phrases {
  exemptionPolicyPhraseFactorFlex:string;
  phrase: string;
  pricingTechnologyPhrase: string;
  disclaimerPhrase: string;
  disclaimerTechPhrase: string;
  offerMessage: string;
  cloverPhrase: string;
}

export class CampaignSimulator extends Item {
  idCampaign: number;
  campaignCode: string;
  description: string;
  hasMdrFlex: boolean;
  hasMdr: boolean;
  mdrWithPrepayment: boolean;
  minAnnualBilling: number;
  maxAnnualBilling: number;
  hasNegotiation: boolean;
  technologyList: Array<TechnologyInformationDto>;
  amountTechs: Array<number>;
}
